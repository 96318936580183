<template>
    <div class="main-pdding">
        <div class="main-box">
            <div class="title">
                <div class="t-label">
                    <label @click="$router.push('/notice/notList')">公告管理</label>
                </div>
                <img src="@/assets/images/icon/arrow-rigth-black.png" />
                <div class="t-label current">
                    <label>{{ type == 1 ? "添加公告" : "修改公告" }}</label>
                </div>
            </div>

            <el-form class="not-form" ref="addFormRef" :model="submitData" label-width="100px" :rules="rules">
                <el-form-item label="公告标题" prop="title">
                    <el-input style="width: 50%" v-model="submitData.title" class="edit-input" placeholder="请输入公告标题"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input style="width: 50%" v-model.number="submitData.sort" class="edit-input" placeholder="请输入排序"></el-input>
                </el-form-item>
                <el-form-item label="发布时间" prop="noticeTime">
                    <el-date-picker
                        class="edit-input"
                        style="width: 50%"
                        v-model="submitData.noticeTime"
                        type="datetime"
                        placeholder="选择发布时间"
                        value-format="yyyy-M-d H:m-s"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="公告状态" prop="status">
                    <el-switch v-model="submitData.status" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
                <el-form-item label="公告内容" prop="noticeContent" style="height: 400px">
                    <div style="height: 380px">
                        <quill-editor
                            class="editor"
                            ref="myQuillEditor"
                            v-model="submitData.noticeContent"
                            :options="editorOption"
                            @change="onEditorChange($event)"
                        ></quill-editor>
                    </div>
                </el-form-item>
            </el-form>
            <div class="bottom-btn">
                <div>
                    <el-button class="preview-btn" @click="$router.push('/notice/notList')">取消</el-button>
                    <el-button v-if="type == 1" type="primary" @click="addInfo">添加</el-button>
                    <el-button v-else-if="type == 2" type="primary" @click="editInfo">编辑</el-button>
                </div>
            </div>
        </div>
        <!-- 不需要显示,主要是将图片上传到服务器返回url -->
        <addImg @upimgSuccess="upimgSuccess" v-show="false"></addImg>
    </div>
</template>

<script>
import addImg from "@/components/addIimage/addImg";
import { sessionGetKey } from "@/utils/sessionStorage.js";
import rules from "@/utils/rules";
import editorOption from "@/utils/noticeOption.js";
import Quill from "quill";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageResize", ImageResize);
export default {
    components: {
        addImg,
    },
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        if (this.$route.query.data) {
            const { content, id, sort, status, time, title } = JSON.parse(this.$route.query.data);
            this.submitData.noticeContent = content;
            this.submitData.id = id;
            this.submitData.sort = sort;
            this.submitData.status = status;
            this.submitData.noticeTime = time;
            this.submitData.title = title;
        }
    },
    data() {
        return {
            type: "1",
            platform_id: "",
            submitData: {
                id: "",
                title: "",
                sort: "",
                noticeTime: "",
                status: 1,
                noticeContent: "",
            },
            rules: rules,
            editorOption: editorOption,
        };
    },
    methods: {
        // 添加
        addInfo() {
            const that = this;
            let { title, sort, noticeTime, status, noticeContent } = this.submitData;

            that.$refs.addFormRef.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.notice.addNot,
                        method: "post",
                        data: {
                            title,
                            sort,
                            time: noticeTime,
                            status,
                            content: noticeContent,
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/notice/notList");
                    });
                }
            });
        },
        //修改
        editInfo() {
            const that = this;
            let { id, title, sort, noticeTime, status, noticeContent } = this.submitData;
            that.$refs.addFormRef.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.notice.editNot,
                        method: "post",
                        data: {
                            id,
                            title,
                            sort,
                            time: noticeTime,
                            status,
                            content: noticeContent,
                        },
                    }).then((res) => {
                        that.$router.push("/notice/notList");
                    });
                }
            });
        },
        // 上传图片之后返回图片地址，添加到编辑器中
        upimgSuccess(imgUrl) {
            let quill = this.$refs.myQuillEditor.quill;
            let length = quill.getSelection().index;
            quill.insertEmbed(length, "image", imgUrl);
            quill.setSelection(length + 1);
        },
        // 内容改变事件，只需要这一个方法就够了
        onEditorChange({ quill, html, text }) {
            console.log("editor change!", quill, html, text);
        },
    },
};
</script>

<style lang="scss" scoped>
.edit-input {
    border-radius: 6px;
    border: 1px solid #999;
    /deep/.el-input__inner {
        border: 0 !important;
    }
}

.title {
    margin-bottom: 20px;
    .t-label {
        display: inline-block;
        height: 35px;

        label {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            font-weight: 600;
        }
    }

    img {
        vertical-align: middle;
        width: 24px;
        height: 24px;
    }

    .current {
        border-bottom: 2px solid #4458fe;
        label {
            color: #4458fe;
        }
    }
}

.bottom-btn {
    margin-top: 25px;
    margin-left: 100px;
    .preview-btn {
        border: 1px solid #999999;
        color: #999999;
        margin-right: 20px;
    }
}

.not-form {
    width: 80%;
}

.editor {
    line-height: normal !important;
    height: 300px;
}
</style>
